import React, { useEffect } from "react"
import NavBar from "../components/Navbar/NavBar"
import "../styles/global.scss"

export default function MainLayout({ children }) {
  useEffect(() => {
    var lazyloadImages
    var lazyloadStyle

    if ("IntersectionObserver" in window) {
      lazyloadImages = document.querySelectorAll(".lazy")
      lazyloadStyle = document.querySelectorAll(".lazy-style")

      var imageObserver = new IntersectionObserver(function (
        entries,
        observer
      ) {
        entries.forEach(function (entry) {
          if (entry.isIntersecting) {
            var image = entry.target
            image.src = image.dataset.src
            image.classList.remove("lazy")
            imageObserver.unobserve(image)

            if (entry.target.classList.contains("lazy-style")) {
              entry.target.style.backgroundImage = `url(${entry.target.dataset.bgImg})`
            }
          }
        })
      })

      lazyloadImages.forEach(function (image) {
        imageObserver.observe(image)
      })

      lazyloadStyle.forEach(function (styleElement) {
        imageObserver.observe(styleElement)
      })
    } else {
      var lazyloadThrottleTimeout
      lazyloadImages = document.querySelectorAll(".lazy")

      function lazyload() {
        if (lazyloadThrottleTimeout) {
          clearTimeout(lazyloadThrottleTimeout)
        }

        lazyloadThrottleTimeout = setTimeout(function () {
          var scrollTop = window.pageYOffset
          lazyloadImages.forEach(function (img) {
            if (img.offsetTop < window.innerHeight + scrollTop) {
              img.src = img.dataset.src
              img.classList.remove("lazy")
            }
          })
          if (lazyloadImages.length === 0) {
            document.removeEventListener("scroll", lazyload)
            window.removeEventListener("resize", lazyload)
            window.removeEventListener("orientationChange", lazyload)
          }
        }, 20)
      }

      document.addEventListener("scroll", lazyload)
      window.addEventListener("resize", lazyload)
      window.addEventListener("orientationChange", lazyload)
    }
  }, [])

  return (
    <div className="main-layout">
      <NavBar></NavBar>
      <div>
        <div className="main-layout__content">{children}</div>
      </div>
    </div>
  )
}
